import type { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import type { FormField } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  FormLayoutViewFragment,
  GetFormQuery,
  GetFormQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { cleanNullValues } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { FieldValues } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { getCustomComponentIdFromFormId } from '../../../helpers/components/CustomComponentsHelper';
import {
  createOrMergeCustomFormFieldType,
  createOrOverrideCustomFormFieldGroupType,
  isFormFieldReference,
  isFormFieldType,
  isFormGroupField,
  mergeValidations
} from '../../../helpers/form/FormHelper/FormHelper';
import useCachedComponent from '../../useCachedComponent';
import useQueryWithTracing from '../../useQueryWithTracing';
import type { FormFieldVariant, FormGroupFieldType } from '../enums';
import type {
  FormFieldGroupSpecDefinition,
  FormFieldSpecDefinition,
  FormFieldType,
  FormGroupFieldTypeBehavior,
  FormLayout,
  FormRowDefinition,
  FormSpec
} from '../types';
import useFormSpecFromLayout from '../useFormSpecFromLayout';
import getFormQuery from './GetForm.query.graphql';
import { formBypassOptions } from '../../../helpers/form/FormHelper/FormBypassHelper';

const log = getLog(module);

function processLayoutRow<FormDataT extends FieldValues>(
  row: FormRowDefinition,
  formFields: Record<string, FormField>,
  id: string,
  defaultFormSpec: FormSpec<FormDataT>,
  client: ApolloClient<object>,
  formId: string,
  fieldTypes: Record<string, FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>>,
  fieldGroupTypes: Record<string, FormFieldGroupSpecDefinition<FormDataT>>
): FormFieldSpecDefinition<FormDataT>[] {
  const result: FormFieldSpecDefinition<FormDataT>[] = [];
  if (isFormFieldReference(row)) {
    const field = formFields[id];
    const customProp = defaultFormSpec.customProps?.find(p => {
      return p.name === id;
    });
    const fieldDefinitionParam: FormFieldType<
      FieldPath<FormDataT>,
      FormDataT,
      FormFieldVariant
    > = fieldTypes[id];
    const adjustedFormField: FormFieldSpecDefinition<FormDataT> = createOrMergeCustomFormFieldType(
      client,
      formId,
      field,
      fieldDefinitionParam,
      customProp
    );

    const finalFormField = adjustedFormField ?? fieldTypes[id] ?? null;

    if (finalFormField) {
      result.push(finalFormField);
    }
  } else if (isFormGroupField(row)) {
    const fieldGroup = fieldGroupTypes[id];
    if (fieldGroup) {
      result.push(fieldGroup);
    } else {
      const customFieldGroup: FormFieldGroupSpecDefinition<FormDataT> =
        createOrOverrideCustomFormFieldGroupType(
          client,
          formId,
          formFields,
          defaultFormSpec.customProps,
          row,
          fieldGroupTypes[id]
        );
      if (customFieldGroup) {
        result.push(customFieldGroup);
      }
    }
    row.items.forEach(item => {
      const processedItem = processLayoutRow(
        item,
        formFields,
        item.id,
        defaultFormSpec,
        client,
        formId,
        fieldTypes,
        fieldGroupTypes
      );

      if (processedItem?.length > 0) {
        result.push(...processedItem);
      }
    });
  }

  return result;
}

/**
 * Returns a tuple array. The first argument is true if the request to get form spec is loading else first.
 * The second argument is the form specification. If the request is loading, then the default form specification is
 * returned else the merged form specification based on the form JSON returned from LIA and the form specification.
 * @param defaultFormSpec the form specification for the form
 */
export default function useFormSpec<FormDataT extends FieldValues>(
  defaultFormSpec: FormSpec<FormDataT>
): [boolean, FormSpec<FormDataT>] {
  const { bypassFormLayout, bypassFormValidation } = formBypassOptions();
  const { formId, formSchema: defaultFormSchema } = defaultFormSpec;
  const { schema: { layout: schemaLayout = null } = {}, cx } = defaultFormSchema;
  const customComponentId = getCustomComponentIdFromFormId(formId);
  const bypassQuery = customComponentId != null || (bypassFormLayout && bypassFormValidation);
  const {
    loading: formSchemaLoading,
    data: formSchema,
    error: formSchemaError
  } = useQueryWithTracing<GetFormQuery, GetFormQueryVariables>(module, getFormQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      id: formId
    },
    skip: bypassQuery
  });

  const client = useApolloClient();
  const getFormSpecFromLayout = useFormSpecFromLayout<FormDataT>();
  const cachedComponent = useCachedComponent(customComponentId, false);

  let layout: FormLayout | FormLayoutViewFragment;

  /**
   * IMPORTANT! The fields that end up here when no customComponentId is present will be pulled from the component's form.json file
   * _as it is deployed_, not as it exists on your local filesystem. (unless you are running a local LIA instance)
   **/
  let fields: FormField[];

  const { form: cachedForm } = cachedComponent?.data ?? {};
  if (cachedForm) {
    const { layout: cachedLayout, fields: cachedFields } = cachedForm;
    layout = cachedLayout;
    fields = cachedFields;
  } else {
    if (bypassQuery) {
      return [
        false,
        getFormSpecFromLayout(schemaLayout as unknown as FormLayout, cx, defaultFormSpec)
      ];
    }

    if (formSchemaLoading || formSchemaError || !formSchema?.form?.result) {
      if (formSchemaError) {
        log.error(
          'Error retrieving form definition for form with id %s. Falling back to default',
          formId
        );
      }

      if (!formSchemaLoading && !formSchema?.form?.result) {
        log.error(
          'Server did not return valid response for form schema with id %s. Falling back to default',
          formId
        );
      }

      if (schemaLayout) {
        return [
          formSchemaLoading,
          getFormSpecFromLayout(schemaLayout as unknown as FormLayout, cx, defaultFormSpec)
        ];
      }

      return [formSchemaLoading, defaultFormSpec];
    }
    layout = formSchema?.form?.result?.form.layout as unknown as FormLayout;
    fields = formSchema?.form?.result?.form.fields;
  }
  const customOrDefaultLayout = layout ?? schemaLayout;
  const sanitizedLayout = cleanNullValues(customOrDefaultLayout);
  const formFields: Record<string, FormField> = {};
  if (fields) {
    fields.forEach(field => {
      const { id } = field;
      formFields[id] = field;
    });
  }
  const fieldTypes: Record<
    string,
    FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  > = {};
  const fieldGroupTypes: Record<string, FormFieldGroupSpecDefinition<FormDataT>> = {};
  defaultFormSpec.formFields.forEach(formFieldDefinition => {
    if (formFieldDefinition) {
      if (isFormFieldType(formFieldDefinition)) {
        const formField = formFieldDefinition as FormFieldType<
          FieldPath<FormDataT>,
          FormDataT,
          FormFieldVariant
        >;
        fieldTypes[formField.name] = formField;
        const field = formFields[formField.name];
        if (field) {
          const mergedValidation = mergeValidations(
            field.validation,
            formField.validations,
            client
          );
          if (mergedValidation) {
            log.debug(
              'Merged validation for form with id: %s for %s is %O',
              formId,
              formField.name,
              mergedValidation
            );
          }
          formField.validations = mergedValidation;
          formField.specialChecks = field?.validation?.specialChecks ?? [];
        }
      } else {
        const group = formFieldDefinition as FormGroupFieldTypeBehavior<
          unknown,
          unknown,
          FormGroupFieldType
        >;
        fieldGroupTypes[group.id] = formFieldDefinition;
      }
    }
  });
  const adjustedFormSpec: FormSpec<FormDataT> = { ...defaultFormSpec };
  if (layout) {
    const adjustedFormFields: Array<FormFieldSpecDefinition<FormDataT>> = [];
    layout.rows.forEach(row => {
      const { id } = row;
      const processedRow = processLayoutRow(
        row,
        formFields,
        id,
        defaultFormSpec,
        client,
        formId,
        fieldTypes,
        fieldGroupTypes
      );

      if (processedRow?.length > 0) {
        adjustedFormFields.push(...processedRow);
      }
    });
    adjustedFormSpec.formFields = adjustedFormFields;
  }

  if (bypassFormValidation) {
    return [
      false,
      getFormSpecFromLayout(sanitizedLayout as unknown as FormLayout, cx, defaultFormSpec)
    ];
  } else if (bypassFormLayout) {
    return [
      false,
      getFormSpecFromLayout(schemaLayout as unknown as FormLayout, cx, adjustedFormSpec)
    ];
  }
  return [
    false,
    getFormSpecFromLayout(sanitizedLayout as unknown as FormLayout, cx, adjustedFormSpec)
  ];
}
