import type { EntityCustomFormField } from '@aurora/shared-generated/types/graphql-schema-types';
import type { I18n } from '@aurora/shared-types/texts';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type React from 'react';
import type { ColProps, RowProps } from 'react-bootstrap';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { FieldValues, PathValue } from 'react-hook-form';
import type { Mode } from 'react-hook-form/dist/types/form';
import type { FieldPathValue, FieldPath, Path } from 'react-hook-form/dist/types/path';
import { ButtonVariant } from '../../../components/common/Button/enums';
import type { AsyncSearchableSelectFieldSpec } from '../../../components/form/AsyncSearchableSelectField/AsyncSearchableSelectField';
import type { AvatarEditorFieldSpec } from '../../../components/form/AvatarEditorField';
import type { BackgroundImageWithPropertiesSpec } from '../../../components/form/BackgroundImageWithPropertiesField';
import type { BoundaryPaddingSpec } from '../../../components/form/BoundaryPaddingField/BoundaryPaddingField';
import type { CheckFieldSpec } from '../../../components/form/CheckField/CheckField';
import type { CheckWithTextFieldSpec } from '../../../components/form/CheckWithTextField/CheckWithTextField';
import type { ColorSwatchPickerSpec } from '../../../components/form/ColorSwatchPickerField/ColorSwatchPickerField';
import type { ContentWrapperFieldSpec } from '../../../components/form/ContentWrapperField/ContentWrapperField';
import type { CreatableMultiSelectFieldSpec } from '../../../components/form/CreatableMultiSelectField/CreatableMultiSelectField';
import type { CreatableSingleSelectFieldSpec } from '../../../components/form/CreatableSingleSelectField/CreatableSingleSelectField';
import type { RegistrationDateFieldSpec } from '../../../components/form/DateInputField/DateInputField';
import type { DatetimeSpec } from '../../../components/form/DateTimeEditorField/DateTimeEditorField';
import type { DatetimeRangeSelectorFieldSpec } from '../../../components/form/DateTimeRangeSelectorField/DateTimeRangeSelectorField';
import type { DetailedSelectFieldSpec } from '../../../components/form/DetailedSelectField/DetailedSelectField';
import type { DraggableMultiSelectFormFieldSpec } from '../../../components/form/DraggableMultiSelectFormField/DraggableMultiSelectFormField';
import type { DropdownSelectFieldSpec } from '../../../components/form/DropdownSelectField/DropdownSelectField';
import type { FormActionButtonBarPosition } from '../../../components/form/enums';
import {
  FormActionButtonsPosition,
  FormCheckInputType,
  FormFieldVariant,
  FormGroupFieldType,
  FormInputFieldInputType
} from '../../../components/form/enums';
import type { FileFieldSpec } from '../../../components/form/FileField/FileField';
import type { GroupedDropDownSelectFieldSpec } from '../../../components/form/GroupedDropDownSelectField/GroupedDropDownSelectField';
import type { GroupHubDiscussionStylesFieldSpec } from '../../../components/form/GroupHubDiscussionStylesField/GroupHubDiscussionStylesField';
import type { IconRadioSpec } from '../../../components/form/IconRadioField/IconRadioField';
import type { ImageUploadSpec } from '../../../components/form/ImageUploadField/ImageUploadField';
import type { InputFieldSpec } from '../../../components/form/InputField/InputField';
import type { InputGroupFieldSpec } from '../../../components/form/InputGroupField/InputGroupField';
import type { LocationFieldSpec } from '../../../components/form/LocationField/LocationField';
import type { MultiCheckFieldSpec } from '../../../components/form/MultiCheckField/MultiCheckField';
import type { MultiImageUploadSpec } from '../../../components/form/MultiImageUploadField/MultiImageUploadField';
import type { MultiLanguageUrlInputFieldSpec } from '../../../components/form/MultiLanguageUrlInputField/MultiLanguageUrlInputField';
import type { MultiSelectFieldSpec } from '../../../components/form/MultiSelectField/MultiSelectField';
import type { NewPasswordFieldSpec } from '../../../components/form/NewPasswordField/NewPasswordField';
import type { NodePickerFieldSpec } from '../../../components/form/NodePickerField/NodePickerField';
import type { PasswordFieldSpec } from '../../../components/form/PasswordField/types';
import type { PillRadioSpec } from '../../../components/form/PillRadioField/PillRadioField';
import type { PlaceholderFormFieldSpec } from '../../../components/form/PlaceholderFormField/PlaceholderFormField';
import type { RadioFieldSpec } from '../../../components/form/RadioField/RadioField';
import type { RadioWithCustomInputSpec } from '../../../components/form/RadioWithCustomInputField/RadioWithCustomInputField';
import type { RangeFieldSpec } from '../../../components/form/RangeField/RangeField';
import type { SearchableMultiSelectFieldSpec } from '../../../components/form/SearchableMultiSelectField/SearchableMultiSelectField';
import type { SearchFieldSpec } from '../../../components/form/SearchField/SearchField';
import type { SelectFieldSpec } from '../../../components/form/SelectField/SelectField';
import type { TagEditorSpec } from '../../../components/form/TagEditorField/TagEditorField';
import type { TextAreaSpec } from '../../../components/form/TextAreaField/TextAreaField';
import type { TooltipColorPickerSpec } from '../../../components/form/TooltipColorPickerField/TooltipColorPickerField';
import type {
  FieldsetProps,
  FormAction,
  FormActions,
  FormButton,
  FormColumnDefinition,
  FormFieldColumnItem,
  FormFieldGroupSpecDefinition,
  FormFieldRowItem,
  FormFieldsetCommonSpecs,
  FormFieldsetItem,
  FormFieldsetType,
  FormFieldSpecDefinition,
  FormFieldType,
  FormLayout,
  FormOptions,
  FormRowDefinition,
  FormSchemaDefinition,
  FormSpec,
  LayoutFormField,
  Legend
} from '../../../components/form/types';
import type { UserPickerFieldSpec } from '../../../components/form/UserPickerField/UserPickerField';
import type { ComponentProp } from '../../components/CustomComponentsHelper';
import { getDefaultValuesForCustomFields } from '../../custom/CustomFieldHelper';
import { performActionOnFormFieldSpec } from '../FormHelper/FormHelper';

const log = getLog(module);

/**
 * Builder to build the form specification to supply to the InputEditForm. The form fields and action buttons will render
 * in the order it is entered while specifying the builder. Specify 'submit', 'cancel' and 'reset' action ids to render
 * the action buttons respectively. All other action ids are considered custom action, and will trigger form submit on click.
 * On 'cancel' action the form data will be null when `onSubmit` of form is called. On 'reset' the form 'onSubmit'
 * is not called.
 *
 * @author Manish Shrestha
 */
class FormBuilder<FormDataT extends FieldValues> {
  private readonly formFields: Array<FormFieldSpecDefinition<FormDataT>>;

  private readonly formId: string;

  private readonly formSchema: FormSchemaDefinition;

  private readonly i18n: I18n<unknown, unknown>;

  private readonly formActionsSpec: FormActions<FormDataT>;

  private readonly formOptions: FormOptions<FormDataT>;

  private readonly customProps?: Array<ComponentProp>;

  private readonly revalidateMode?: Exclude<Mode, 'onTouched' | 'all'>;

  private readonly shouldUnregister?: boolean;

  isMultipleFieldsFocused = false;

  /**
   * Creates an instance of FormBuilder.
   *
   * @param formId id of the form.
   * @param i18n i18n to use for resolving the localized text.
   * @param formSchema form schema
   * @param formOptions form options
   * @param customProps any custom props added to the form.
   * @param revalidateMode revalidate mode
   * @param shouldUnregister should unregister
   */
  constructor(
    formId: string,
    i18n: I18n<unknown, unknown>,
    formSchema: FormSchemaDefinition,
    formOptions?: FormOptions<FormDataT>,
    customProps?: Array<ComponentProp>,
    revalidateMode?: Exclude<Mode, 'onTouched' | 'all'>,
    shouldUnregister?: boolean
  ) {
    this.formFields = [];
    this.formId = formId;
    this.i18n = i18n;
    this.formActionsSpec = {
      formActions: [],
      actionButtonsPosition: FormActionButtonsPosition.RIGHT
    };
    this.formSchema = formSchema;
    this.formOptions = formOptions ?? {};
    this.customProps = customProps ?? [];
    this.revalidateMode = revalidateMode;
    this.shouldUnregister = shouldUnregister;
  }

  /**
   * Adds field of type input to the form.
   *
   * @param options field options
   */
  addInputField<
    NameT extends FieldPath<FormDataT>,
    FormInputFieldInputTypeT extends FormInputFieldInputType = FormInputFieldInputType.TEXT
  >(
    options: Omit<InputFieldSpec<NameT, FormDataT, FormInputFieldInputTypeT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: InputFieldSpec<NameT, FormDataT, FormInputFieldInputTypeT> = {
      ...options,
      fieldVariant: FormFieldVariant.INPUT
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds field of type input to the form.
   *
   * @param options field options
   */
  addInputGroupField<NameT extends FieldPath<FormDataT>>(
    options: Omit<InputGroupFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: InputGroupFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.INPUT_GROUP
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Add range field to the form.
   *
   * @param options field options
   */
  addRangeField<NameT extends FieldPath<FormDataT>>(
    options: Omit<RangeFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: RangeFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.RANGE
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds email field to the form.
   *
   * @param options field options
   */
  addEmailField<NameT extends FieldPath<FormDataT>>(
    options: Omit<
      InputFieldSpec<NameT, FormDataT, FormInputFieldInputType.EMAIL>,
      'fieldVariant' | 'inputType'
    >
  ): FormBuilder<FormDataT> {
    const adjustedOptions: InputFieldSpec<NameT, FormDataT, FormInputFieldInputType.EMAIL> = {
      ...options,
      fieldVariant: FormFieldVariant.INPUT,
      inputType: FormInputFieldInputType.EMAIL
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds new password field to the form. Sets the autoComplete attribute of password field to 'new-password'.
   *
   * @param options field options
   */
  addNewPasswordField<NameT extends FieldPath<FormDataT>>(
    options: Omit<PasswordFieldSpec<NameT, FormDataT>, 'fieldVariant' | 'inputType'> & {
      passwordViewable?: boolean;
    }
  ): FormBuilder<FormDataT> {
    if (process.env.NEXT_PUBLIC_PASSWORD_VALIDATION_WIP_FEATURES_ENABLED !== 'true') {
      return this.addPasswordField(options);
    }

    let adjustedAttributes = options?.attributes;

    if (adjustedAttributes && !adjustedAttributes?.autoComplete) {
      adjustedAttributes.autoComplete = 'new-password';
    } else {
      adjustedAttributes = {
        ...adjustedAttributes,
        autoComplete: adjustedAttributes?.autoComplete || 'new-password'
      };
    }

    const adjustedOptions: NewPasswordFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.NEW_PASSWORD,
      inputType: options.passwordViewable
        ? FormInputFieldInputType.VIEWABLE_PASSWORD
        : FormInputFieldInputType.PASSWORD,
      attributes: adjustedAttributes
    };

    /** The new password field needs to be notified when the login field changes */
    this.supplementWatchFields(options.usernameName);

    return this.addField(adjustedOptions);
  }

  /**
   * Adds password field to the form. Sets the autoComplete attribute of password field to 'current-password'.
   *
   * @param options field options
   */
  addPasswordField<NameT extends FieldPath<FormDataT>>(
    options: Omit<PasswordFieldSpec<NameT, FormDataT>, 'fieldVariant' | 'inputType'> & {
      passwordViewable?: boolean;
    }
  ): FormBuilder<FormDataT> {
    let adjustedAttributes = options?.attributes;

    if (adjustedAttributes && !adjustedAttributes?.autoComplete) {
      adjustedAttributes.autoComplete = 'current-password';
    } else {
      adjustedAttributes = {
        ...adjustedAttributes,
        autoComplete: adjustedAttributes?.autoComplete || 'current-password'
      };
    }

    const adjustedOptions: PasswordFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.PASSWORD,
      inputType: options.passwordViewable
        ? FormInputFieldInputType.VIEWABLE_PASSWORD
        : FormInputFieldInputType.PASSWORD,
      attributes: adjustedAttributes
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds text field to the form.
   *
   * @param options field options
   */
  addTextInputField<NameT extends FieldPath<FormDataT>>(
    options: Omit<InputFieldSpec<NameT, FormDataT>, 'fieldVariant' | 'inputType'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: InputFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.INPUT,
      inputType: FormInputFieldInputType.TEXT,
      defaultValue:
        options?.defaultValue === null ? ('' as PathValue<FormDataT, NameT>) : options?.defaultValue
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds date input field to the form.
   *
   * @param options field options
   */
  addDateInputField<NameT extends FieldPath<FormDataT>>(
    options: Omit<RegistrationDateFieldSpec<NameT, FormDataT>, 'fieldVariant' | 'inputType'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: RegistrationDateFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DATE_INPUT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds background image field and size, position, and repeat select field to the form.
   *
   * @param options field options
   */
  addBackgroundImageWithPropertiesField<NameT extends FieldPath<FormDataT>>(
    options: Omit<BackgroundImageWithPropertiesSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: BackgroundImageWithPropertiesSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.BACKGROUND_IMAGE_PROPERTIES
    };

    return this.addField(adjustedOptions);
  }

  addTagEditorField<NameT extends FieldPath<FormDataT>>(
    options: Omit<TagEditorSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: TagEditorSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.TAGS
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds checkbox input field to the form.
   *
   * @param options field options
   */
  addCheckField<NameT extends FieldPath<FormDataT>>(
    options: Omit<CheckFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const { inputType, ...restOptions } = options;
    const adjustedOptions: CheckFieldSpec<NameT, FormDataT> = {
      ...restOptions,
      inputType: inputType ?? FormCheckInputType.CHECKBOX,
      fieldVariant: FormFieldVariant.CHECK
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds a one-off field to the form. May or may not actually be wired in with a Controller.
   *
   * @param options field options
   */
  addContentWrapperField<NameT extends FieldPath<FormDataT>>(
    options: Omit<ContentWrapperFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: ContentWrapperFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.CONTENT_WRAPPER
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds radio input field to the form.
   *
   * @param options field options
   */
  addRadioField<NameT extends FieldPath<FormDataT>>(
    options: Omit<RadioFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: RadioFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.RADIO
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds file field to the form.
   *
   * @param options field options
   */
  addFileField<NameT extends FieldPath<FormDataT>>(
    options: Omit<FileFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: FileFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.FILE
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds icon radio input field to the form.
   *
   * @param options field options
   */
  addIconRadioField<NameT extends FieldPath<FormDataT>>(
    options: Omit<IconRadioSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: IconRadioSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.ICON_RADIO
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds color swatch picker to the form.
   *
   * @param options field options
   */
  addColorSwatchPickerField<NameT extends FieldPath<FormDataT>>(
    options: Omit<ColorSwatchPickerSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: ColorSwatchPickerSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.COLOR_SWATCH
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds an image upload field that can accept a single image
   *
   * @param options field options
   */
  addImageUploadField<NameT extends FieldPath<FormDataT>>(
    options: Omit<ImageUploadSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: ImageUploadSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.IMAGE_UPLOAD
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds an image-upload field that can handle multiple image uploads.
   *
   * @param options field options
   */
  addMultiImageUploadField<NameT extends FieldPath<FormDataT>>(
    options: Omit<MultiImageUploadSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: MultiImageUploadSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.MULTI_IMAGE_UPLOAD
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds detailed select field to the form.
   *
   * @param options field options
   */
  addDetailedSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<DetailedSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: DetailedSelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DETAILED_SELECT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds detailed select field to the form.
   *
   * @param options field options
   */
  addDropdownSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<DropdownSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: DropdownSelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DROPDOWN_SELECT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds grouped dropdown select field to the form.
   *
   * @param options field options
   */
  addGroupedDropdownSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<GroupedDropDownSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: GroupedDropDownSelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.GROUPED_DROPDOWN_SELECT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds a draggable multi select field to the form.
   *
   * @param options field options
   */
  addDraggableMultiSelectFormField<NameT extends FieldPath<FormDataT>>(
    options: Omit<DraggableMultiSelectFormFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: DraggableMultiSelectFormFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DRAGGABLE_MUTLI_SELECT_FIELD
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds detailed select field to the form.
   *
   * @param options field options
   */
  addDateTimeRangeSelectorField<NameT extends FieldPath<FormDataT>>(
    options: Omit<DatetimeRangeSelectorFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: DatetimeRangeSelectorFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DATE_TIME_RANGE_SELECT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Add select input field to the form.
   *
   * @param options field options
   */
  addSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<SelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: SelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.SELECT
    };

    return this.addField(adjustedOptions);
  }

  addMultiSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<MultiSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: MultiSelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.MULTI_SELECT
    };

    return this.addField(adjustedOptions);
  }

  addSearchableMultiSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<SearchableMultiSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: SearchableMultiSelectFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.SEARCHABLE_MULTI_SELECT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds field of type input to the form.
   *
   * @param options field options
   */
  addTextAreaField<NameT extends FieldPath<FormDataT>>(
    options: Omit<TextAreaSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: TextAreaSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.TEXT_AREA
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds field of type input to the form.
   *
   * @param options field options
   */
  addSearchField<NameT extends FieldPath<FormDataT>>(
    options: Omit<SearchFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: SearchFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.SEARCH
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Add date time picker field to the form.
   *
   * @param options field options
   */
  addDatetimeField<NameT extends FieldPath<FormDataT>>(
    options: Omit<DatetimeSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: DatetimeSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.DATETIME
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds a tooltip color picker field to the form.
   *
   * @param options field options
   */
  addTooltipColorPickerField<NameT extends FieldPath<FormDataT>>(
    options: Omit<TooltipColorPickerSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: TooltipColorPickerSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.COLOR_PICKER,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds a search-select field to the form. This field is uses react-select
   *
   * @param options field options
   */

  /* addSearchableSelectField<NameT extends FieldPath<FormDataT>>(
	  options: Omit<SearchableSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
	): FormBuilder<FormDataT> {
	  const adjustedOptions: SearchableSelectFieldSpec<NameT, FormDataT> = {
		fieldVariant: FormFieldVariant.SEARCHABLE_SELECT,
		...options
	  };
	  return this.addField(adjustedOptions);
	} */

  /**
   * Adds a pill style radio field to the form.
   *
   * @param options field options
   */
  addPillRadioField<NameT extends FieldPath<FormDataT>>(
    options: Omit<PillRadioSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: PillRadioSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.PILL_RADIO,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  addMultiCheckboxField<NameT extends FieldPath<FormDataT>>(
    options: Omit<MultiCheckFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: MultiCheckFieldSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds multiple input field which allows us to edit urls for community level allowed languages
   *
   * @param options field options
   */
  addMultiLanguageUrlInputField<NameT extends FieldPath<FormDataT>>(
    options: Omit<
      MultiLanguageUrlInputFieldSpec<NameT, FormDataT>,
      'fieldVariant' | 'defaultValue' | 'validations'
    >
  ): FormBuilder<FormDataT> {
    const adjustedOptions: MultiLanguageUrlInputFieldSpec<NameT, FormDataT> = {
      defaultValue: options.languages as PathValue<FormDataT, NameT>,
      fieldVariant: FormFieldVariant.MULTI_LANGUAGE_URL_INPUT_FIELD,
      formGroupSpec: {
        label: {
          className: 'd-none'
        }
      },
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds field of type input boundary padding to the form.
   *
   * @param options field options
   */
  addBoundaryPaddingField<NameT extends FieldPath<FormDataT>>(
    options: Omit<BoundaryPaddingSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: BoundaryPaddingSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.BOUNDARY_PADDING
    };

    return this.addField(adjustedOptions);
  }

  /**
   * adds a placeholder form field which renders the specified content. The value of the form field is always null.
   *
   * @param options field options
   */
  addPlaceholderFormField<NameT extends FieldPath<FormDataT>>(
    options: Omit<PlaceholderFormFieldSpec<NameT, FormDataT>, 'fieldVariant' | 'defaultValue'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: PlaceholderFormFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.PLACEHOLDER_FORM_FIELD,
      defaultValue: '' as FormDataT[NameT]
    };

    return this.addField(adjustedOptions);
  }

  /**
   * adds a Check Field with Input form field to the form.
   *
   * @param options field options
   */
  addCheckWithTextField<NameT extends FieldPath<FormDataT>>(
    options: Omit<CheckWithTextFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: CheckWithTextFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.CHECK_WITH_TEXT_FIELD
    };

    return this.addField(adjustedOptions);
  }

  /**
   * adds a Location form field to the form.
   *
   * @param options field options
   */
  addLocationField<NameT extends FieldPath<FormDataT>>(
    options: Omit<LocationFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: LocationFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.LOCATION
    };

    return this.addField(adjustedOptions);
  }

  /**
   * adds a custom form field used for creating/updating GroupHub discussions
   *
   * @param options field options
   */
  addGroupHubDiscussionStylesField<NameT extends FieldPath<FormDataT>>(
    options: Omit<GroupHubDiscussionStylesFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: GroupHubDiscussionStylesFieldSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.GROUP_HUB_DISCUSSION_STYLES,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds the avatar editor field to the form.
   *
   * @param options field options
   */
  addAvatarEditorField<NameT extends FieldPath<FormDataT>>(
    options: Omit<AvatarEditorFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: AvatarEditorFieldSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.AVATAR_EDITOR,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds the custom CreatableSingleSelectField to the form
   *
   * @param options field options
   */
  addCreatableSingleSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<CreatableSingleSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: CreatableSingleSelectFieldSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.CREATABLE_SINGLE_SELECT,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds the custom AsyncSearchableSelectFieldSpec to the form
   *
   * @param options field options
   */
  addAsyncSearchableSelectField<NameT extends FieldPath<FormDataT>, EntityT>(
    options: Omit<AsyncSearchableSelectFieldSpec<NameT, FormDataT, EntityT>, 'fieldVariant'>
  ) {
    const adjustedOptions: AsyncSearchableSelectFieldSpec<NameT, FormDataT, EntityT> = {
      fieldVariant: FormFieldVariant.ASYNC_SEARCHABLE_SELECT,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds a CreatableMultiSelect field to the form.
   *
   * @param options field options
   */
  addCreatableMultiSelectField<NameT extends FieldPath<FormDataT>>(
    options: Omit<CreatableMultiSelectFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: CreatableMultiSelectFieldSpec<NameT, FormDataT> = {
      fieldVariant: FormFieldVariant.CREATABLE_MULTI_SELECT,
      ...options
    };
    return this.addField(adjustedOptions);
  }

  /**
   * Adds a NodePickerField to the form.
   *
   * @param options field options
   */
  addNodePickerField<NameT extends FieldPath<FormDataT>>(
    options: Omit<NodePickerFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: NodePickerFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.NODE_PICKER
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds a UserPickerField to the form.
   *
   * @param options field options
   */
  addUserPickerField<NameT extends FieldPath<FormDataT>>(
    options: Omit<UserPickerFieldSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: UserPickerFieldSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.USER_PICKER
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds radio input with custom option field to the form.
   *
   * @param options field options
   */
  addRadioWithCustomInputField<NameT extends FieldPath<FormDataT>>(
    options: Omit<RadioWithCustomInputSpec<NameT, FormDataT>, 'fieldVariant'>
  ): FormBuilder<FormDataT> {
    const adjustedOptions: RadioWithCustomInputSpec<NameT, FormDataT> = {
      ...options,
      fieldVariant: FormFieldVariant.RADIO_WITH_CUSTOM_INPUT
    };

    return this.addField(adjustedOptions);
  }

  /**
   * Adds a field to the form.
   *
   * @param fieldSpec form field specification.
   */
  addField<NameT extends FieldPath<FormDataT>, ValueT = FieldPathValue<FormDataT, NameT>>(
    fieldSpec: FormFieldType<NameT, FormDataT, FormFieldVariant, ValueT>
  ): FormBuilder<FormDataT> {
    if (fieldSpec.focus && this.isMultipleFieldsFocused) {
      log.warn('More than one field focused.');
    }
    if (fieldSpec.focus) {
      this.isMultipleFieldsFocused = true;
    }
    this.formFields.push(
      fieldSpec as unknown as FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
    );
    return this;
  }

  /**
   * Adds entity custom fields to the form.
   *
   * @param customFormFields the custom form fields to add.
   */
  addEntityCustomFormFields(
    customFormFields: Array<EntityCustomFormField> | null
  ): FormBuilder<FormDataT> {
    const customFieldsDefaults: Record<string, unknown> =
      getDefaultValuesForCustomFields(customFormFields);
    const customField: FormFieldType<
      FieldPath<FormDataT>,
      FormDataT,
      FormFieldVariant.CUSTOM_FIELD
    > = {
      fieldVariant: FormFieldVariant.CUSTOM_FIELD,
      fields: customFormFields,
      name: 'custom' as FieldPath<FormDataT>,
      defaultValue: customFieldsDefaults,
      isVisible: {
        watchFields: null,
        callback: () => {
          return customFormFields.length > 0;
        }
      }
    } as unknown as FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant.CUSTOM_FIELD>;
    this.addField(customField);
    return this;
  }

  /**
   * @deprecated Use layout to create your layout and {@link addFieldGroupProperties} to add any additional properties from code.
   * All fields used within the field group should be explicitly added from the form builder as any other field.
   *
   * Adds a field group for the form.
   *
   * @param id for the field group. This id should be unique throughout all fields and fields group in form.
   * @param props fieldset properties (with classnames).
   * @param fieldBuilder a function with an instance of {@link FormBuilder} to specify collection of fields for the group.
   * @param options Options for field group
   * @param viewVariant the view variant for the field group
   * @param useIDAsHtmlID whether to use the passed id in the markup for the fieldset
   * @param ref a DOM ref to the fieldset HTMLDivElement
   */
  addFieldGroup(
    id: string,
    fieldBuilder: (fieldBuilder: FormBuilder<FormDataT>) => void,
    props?: FieldsetProps,
    options?: FormFieldsetCommonSpecs,
    viewVariant?: string,
    useIDAsHtmlID?: boolean,
    ref?: React.RefObject<HTMLDivElement>
  ): FormBuilder<FormDataT> {
    const fieldBuilderInstance = new FormBuilder<FormDataT>(
      this.formId,
      this.i18n,
      this.formSchema,
      this.formOptions
    );
    fieldBuilder(fieldBuilderInstance);
    this.formFields.push({
      id,
      props,
      items: fieldBuilderInstance
        .build()
        .formFields.map(item => item as FormFieldsetItem<FormDataT>),
      type: FormGroupFieldType.FIELDSET,
      ...options,
      viewVariant,
      useIDAsHtmlID,
      ref
    });
    return this;
  }

  /**
   * Adds a field group properties for the form. Use this to add additional properties from code to merge with layout spec.
   * All fields used within the field group should be explicitly added from the form builder as any other field.
   *
   * @param id for the field group. This id should be unique throughout all fields and fields group in form.
   * @param props fieldset properties (with classnames).
   * @param options Options for field group
   * @param viewVariant the view variant for the field group
   * @param useIDAsHtmlID whether to use the passed id in the markup for the fieldset
   * @param ref a DOM ref to the fieldset HTMLDivElement
   */
  addFieldGroupProperties(
    id: string,
    props?: FieldsetProps,
    options?: FormFieldsetCommonSpecs,
    viewVariant?: string,
    useIDAsHtmlID?: boolean,
    ref?: React.RefObject<HTMLDivElement>
  ): FormBuilder<FormDataT> {
    this.formFields.push({
      id,
      props,
      items: [],
      type: FormGroupFieldType.FIELDSET,
      ...options,
      viewVariant,
      useIDAsHtmlID,
      ref
    });
    return this;
  }

  /**
   * @deprecated Use layout to create your layout and {@link addRow} to add any additional properties from code.
   * All fields used within the row should be explicitly added from the form builder as any other field.
   *
   * Adds a row to the form.
   *
   * @param id for the row group. This id should be unique throughout all fields and fields group in form.
   * @param fieldBuilder builder to build the items inside the row.
   * @param props bootstrap Row component properties.
   * @param className className to apply to the Row element.
   * @param as element to render the row as.
   * @param viewVariant the view variant for the row
   */
  addRow(
    id: string,
    fieldBuilder: (fieldBuilder: FormBuilder<FormDataT>) => void,
    props?: RowProps,
    className?: string,
    as?: React.ElementType,
    viewVariant?: string
  ): FormBuilder<FormDataT> {
    const fieldBuilderInstance = new FormBuilder<FormDataT>(
      this.formId,
      this.i18n,
      this.formSchema,
      this.formOptions
    );
    fieldBuilder(fieldBuilderInstance);
    this.formFields.push({
      id,
      items: fieldBuilderInstance
        .build()
        .formFields.map(item => item as FormFieldRowItem<FormDataT>),
      props,
      as,
      type: FormGroupFieldType.ROW,
      className,
      viewVariant
    });
    return this;
  }

  /**
   * Adds row properties to the form. Use this to add additional properties from code to merge with layout spec.
   * All fields used within the row should be explicitly added from the form builder as any other field.
   *
   * @param id for the row group. This id should be unique throughout all fields and fields group in form.
   * @param props bootstrap Row component properties.
   * @param className className to apply to the Row element.
   * @param as element to render the row as.
   * @param viewVariant the view variant for the row
   */
  addRowProperties(
    id: string,
    props?: RowProps,
    className?: string,
    as?: React.ElementType,
    viewVariant?: string
  ): FormBuilder<FormDataT> {
    this.formFields.push({
      id,
      items: [],
      props,
      as,
      type: FormGroupFieldType.ROW,
      className,
      viewVariant
    });
    return this;
  }

  /**
   * @deprecated Use layout to create your layout and {@link addFormRowProperties} to add any additional properties from code.
   * All fields used within the Form.Row should be explicitly added from the form builder as any other field.
   *
   * Adds a Form.Row to the form.
   *
   * @param id of for the form row group. This id should be unique throughout all fields and fields group in form.
   * @param fieldBuilder fieldBuilder builder to build the items inside the row.
   * @param className classname to apply to the Form.Row element.
   * @param as as element to render the row as.
   * @param viewVariant the view variant for the form row
   */
  addFormRow(
    id: string,
    fieldBuilder: (fieldBuilder: FormBuilder<FormDataT>) => void,
    className?: string,
    as?: React.ElementType,
    viewVariant?: string
  ): FormBuilder<FormDataT> {
    const fieldBuilderInstance = new FormBuilder<FormDataT>(
      this.formId,
      this.i18n,
      this.formSchema,
      this.formOptions
    );
    fieldBuilder(fieldBuilderInstance);
    this.formFields.push({
      id,
      items: fieldBuilderInstance
        .build()
        .formFields.map(item => item as FormFieldRowItem<FormDataT>),
      as,
      type: FormGroupFieldType.FORM_ROW,
      className,
      viewVariant
    });
    return this;
  }

  /**
   * Adds a Form.Row properties to the form. Use this to add additional properties from code to merge with layout spec.
   * All fields used within the Form.Row should be explicitly added from the form builder as any other field.
   *
   * @param id of for the form row group. This id should be unique throughout all fields and fields group in form.
   * @param className classname to apply to the Form.Row element.
   * @param as as element to render the row as.
   * @param viewVariant the view variant for the form row
   */
  addFormRowProperties(
    id: string,
    className?: string,
    as?: React.ElementType,
    viewVariant?: string
  ): FormBuilder<FormDataT> {
    this.formFields.push({
      id,
      items: [],
      as,
      type: FormGroupFieldType.FORM_ROW,
      className,
      viewVariant
    });
    return this;
  }

  /**
   * @deprecated Use layout to create your layout and {@link addColumnProperties} to add any additional properties from code.
   * All fields used within the column should be explicitly added from the form builder as any other field.
   *
   * Adds a column to the form.
   *
   * @param id id for the column group. This id should be unique throughout all fields and fields group in form.
   * @param fieldBuilder builder to build the items inside the column.
   * @param props bootstrap Col component properties.
   * @param as element to render the column as.
   * @param className className to apply to the Col element.
   */
  addColumn(
    id: string,
    fieldBuilder: (fieldBuilder: FormBuilder<FormDataT>) => void,
    props?: ColProps,
    as?: React.ElementType,
    className?: string
  ): FormBuilder<FormDataT> {
    const fieldBuilderInstance = new FormBuilder<FormDataT>(
      this.formId,
      this.i18n,
      this.formSchema,
      this.formOptions
    );
    fieldBuilder(fieldBuilderInstance);
    this.formFields.push({
      id,
      items: fieldBuilderInstance
        .build()
        .formFields.map(item => item as FormFieldColumnItem<FormDataT>),
      props,
      as,
      type: FormGroupFieldType.COL,
      className
    });
    return this;
  }

  /**
   * Adds column properties to the form. Use this to add additional properties from code to merge with layout spec.
   * All fields used within the column should be explicitly added from the form builder as any other field.
   *
   * @param id id for the column group. This id should be unique throughout all fields and fields group in form.
   * @param props bootstrap Col component properties.
   * @param as element to render the column as.
   * @param className className to apply to the Col element.
   */
  addColumnProperties(
    id: string,
    props?: ColProps,
    as?: React.ElementType,
    className?: string
  ): FormBuilder<FormDataT> {
    this.formFields.push({
      id,
      items: [],
      props,
      as,
      type: FormGroupFieldType.COL,
      className
    });
    return this;
  }

  /**
   * Adds form action to the form. On click of these actions, form submit is triggered except for 'reset' action.
   *
   * @param action form action definition.
   */
  addFormAction(action: FormAction<FormDataT>): FormBuilder<FormDataT> {
    this.formActionsSpec.formActions.push(action);
    return this;
  }

  /**
   * Adds specified form actions to the form. On click of these actions, form submit is triggered except for 'reset' action.
   * @param actions
   */
  addFormActions(actions: Array<FormAction<FormDataT>>): FormBuilder<FormDataT> {
    actions.forEach(action => this.formActionsSpec.formActions.push(action));
    return this;
  }

  /**
   * Adds form action to the form based on action id. On click of these actions, form submit is triggered except for
   * 'reset' action.
   *
   * @param actionId actionId of the form. Specify 'submit' for submit action, 'cancel' for cancel action, and 'reset'
   * for reset action. Any other actionId will behave as a custom action id and will trigger form submit on click.
   */
  addFormActionById(actionId: string): FormBuilder<FormDataT> {
    this.formActionsSpec.formActions.push({
      actionId
    });
    return this;
  }

  /**
   * Adds submit action to the form.
   *
   * @param buttonSpec whether the action button displays loading indicator or not.
   */
  addSubmitAction(buttonSpec?: Omit<FormButton<FormDataT>, 'disabled'>): FormBuilder<FormDataT> {
    return this.addFormAction({
      actionId: 'submit',
      buttonSpec
    });
  }

  /**
   * Adds reset action to the form.
   * @param buttonSpec form button specification for the action id.
   */
  addResetAction(buttonSpec?: FormButton<FormDataT>): FormBuilder<FormDataT> {
    const decoratedButtonSpec: FormButton<FormDataT> = {
      variant: ButtonVariant.LIGHT,
      ...buttonSpec
    };
    return this.addFormAction({
      actionId: 'reset',
      buttonSpec: decoratedButtonSpec
    });
  }

  /**
   * Adds cancel action to the form.
   * @param buttonSpec form button specification for the action id.
   */
  addCancelAction(buttonSpec?: FormButton<FormDataT>): FormBuilder<FormDataT> {
    const decoratedButtonSpec: FormButton<FormDataT> = {
      variant: ButtonVariant.LIGHT,
      ...buttonSpec
    };
    return this.addFormAction({
      actionId: 'cancel',
      buttonSpec: decoratedButtonSpec
    });
  }

  /**
   * Returns a copy of the current form options.
   * Modifying this copy will NOT actually change the options
   * Only used for testability.
   */
  getFormOptions(): FormOptions<FormDataT> {
    return deepClone(this.formOptions);
  }

  /**
   * Set the position of action buttons in the action buttons bar. If actionContextComponent is specified for the form,
   * then the context component will be placed relatively based on the positioning of action buttons.
   * @param actionButtonsPosition one of {@link FormActionButtonsPosition}
   */
  setActionButtonsPosition(
    actionButtonsPosition: FormActionButtonsPosition
  ): FormBuilder<FormDataT> {
    this.formActionsSpec.actionButtonsPosition = actionButtonsPosition;
    return this;
  }

  /**
   * Set the position of action button bar in the form.
   *
   * @param actionButtonBarPosition one of {@link FormActionButtonBarPosition}
   */
  setActionButtonBarPosition(
    actionButtonBarPosition: FormActionButtonBarPosition
  ): FormBuilder<FormDataT> {
    this.formActionsSpec.actionButtonBarPosition = actionButtonBarPosition;
    return this;
  }

  /**
   * Add a classname to the action container element. This contains both the
   * form action context component (if there is one) and the form actions.
   *
   * @param className the classname(s)
   */
  setActionsOuterContainerClassName(className: string): FormBuilder<FormDataT> {
    this.formActionsSpec.outerContainerClassName = className;
    return this;
  }

  /**
   * Add a classname to the action container element. This contains both the
   * form action context component (if there is one) and the form actions.
   *
   * @param className the classname(s)
   */
  setActionsInnerContainerClassName(className: string): FormBuilder<FormDataT> {
    this.formActionsSpec.innerContainerClassName = className;
    return this;
  }

  /**
   * Add a class name to the form actions container element. This element does not contain
   * the form action context component, only the form actions.
   *
   * @param className the classname(s)
   */
  setActionsClassName(className: string): FormBuilder<FormDataT> {
    this.formActionsSpec.className = className;
    return this;
  }

  /**
   * Adds watch fields to the form.
   * If no watch fields exist, this will create them.
   * Otherwise, it will add to the existing fields, without creating duplicates
   */
  supplementWatchFields(...fields: Path<FormDataT>[]): FormBuilder<FormDataT> {
    const watchFields = [...(this.formOptions.fieldWatchers?.watchFields ?? [])];

    fields.forEach(field => {
      if (!watchFields.includes(field)) {
        watchFields.push(field);
      }
    });

    if (this.formOptions.fieldWatchers) {
      this.formOptions.fieldWatchers.watchFields = watchFields;
    } else {
      this.formOptions.fieldWatchers = {
        watchFields,
        callback: () => {}
      };
    }

    return this;
  }

  /**
   * Builds the form builder and returns specification to use with the InputEditForm.
   */
  build(): FormSpec<FormDataT> {
    const {
      formClassName,
      fieldsWrapperClassName,
      formTitleClassName,
      fieldWatchers,
      formGroupFieldSeparator,
      useReCaptcha,
      reCaptchaClassName
    } = this.formOptions;
    return {
      formId: this.formId,
      i18n: this.i18n,
      formSchema: this.formSchema,
      formFields: this.formFields,
      formActionsSpec: this.formActionsSpec,
      customProps: this.customProps,
      formClassName,
      fieldsWrapperClassName,
      formTitleClassName,
      fieldWatchers,
      formGroupFieldSeparator,
      useReCaptcha,
      reCaptchaClassName,
      revalidateMode: this.revalidateMode,
      shouldUnregister: this.shouldUnregister
    };
  }
}

/**
 * Returns the form field as {@link LayoutFormField} if form field is of type form field.
 * @param object object to verify.
 */
function isFormField(object: FormRowDefinition | FormColumnDefinition): object is LayoutFormField {
  return !('items' in object);
}

/**
 * Merges classname from layout json with the classname defined in form spec
 * @param layoutClassName layout classname
 * @param cx layout classname mapper
 * @param className formspec classname
 */
function mergeClassNames(
  { layoutClassName, cx }: { layoutClassName: string; cx: ClassNamesFnWrapper },
  className: string
): string {
  return layoutClassName && className
    ? `${cx(layoutClassName)} ${cx(className)}`
    : layoutClassName
    ? cx(layoutClassName)
    : cx(className);
}

/**
 * Returns FormSpec based on the layout and the default specification. This merging is necessary to not render
 * unnecessary markup for field groups like fieldset, rows, and columns when the field inside it does not render.
 *
 * @param layout layout of the form.
 * @param layoutCX cx for the component that passes the layout
 * @param formSpec actual form spec
 */
export function getFormSpecFromLayout<FormDataT extends FieldValues>(
  layout: FormLayout,
  layoutCX: ClassNamesFnWrapper,
  formSpec: FormSpec<FormDataT>
): FormSpec<FormDataT> {
  const {
    formClassName,
    fieldsWrapperClassName,
    formTitleClassName,
    formGroupFieldSeparator: formSpecFormGroupFieldSeparator,
    formFields,
    i18n,
    formId: id,
    fieldWatchers,
    customProps,
    formSchema,
    useReCaptcha,
    reCaptchaClassName
  } = formSpec;

  const fieldNameToFieldMap: Record<
    string,
    FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  > = {} as Record<string, FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>>;

  const fieldGroupIdToFieldGroupMap: Record<string, FormFieldGroupSpecDefinition<FormDataT>> = {};

  if (formFields) {
    formFields.forEach(fieldDefinition => {
      performActionOnFormFieldSpec(
        fieldDefinition,
        (formFieldSpec: FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>) => {
          fieldNameToFieldMap[formFieldSpec.name] = formFieldSpec;
        },
        formFieldGroupSpec => {
          const { id: groupId } = formFieldGroupSpec;
          fieldGroupIdToFieldGroupMap[groupId] = formFieldGroupSpec;
        }
      );
    });
  }

  function addField(formBuilder: FormBuilder<FormDataT>, layoutFieldSpec: LayoutFormField) {
    const { id: fieldName, className: layoutDefinitionClassName, ...otherFields } = layoutFieldSpec;
    const fieldDefinition = fieldNameToFieldMap[fieldName];
    if (fieldDefinition) {
      const { className: fieldDefinitionClassName, ...otherFieldsDefinition } = fieldDefinition;
      formBuilder.addField({
        ...otherFieldsDefinition,
        ...otherFields,
        className: mergeClassNames(
          { layoutClassName: layoutDefinitionClassName, cx: layoutCX },
          fieldDefinitionClassName
        )
      });
    } else {
      log.error('Field definition not found for field with name %s', fieldName);
    }
  }

  /**
   * Builds the form builder from the item definition in the layout.
   * @param item item to add to the builder.
   * @param formBuilder formBuilder instance for the item.
   */
  function buildForm(
    item: FormRowDefinition | FormColumnDefinition,
    formBuilder: FormBuilder<FormDataT>
  ): void {
    if (isFormField(item)) {
      addField(formBuilder, item);
    } else {
      const { id: groupId, className: layoutClassName, viewVariant } = item;
      const {
        props: fieldSpecProps,
        as: fieldSpecAs,
        className: fieldSpecClassName
      } = fieldGroupIdToFieldGroupMap[groupId] || {};
      const className = mergeClassNames({ layoutClassName, cx: layoutCX }, fieldSpecClassName);

      switch (item.type) {
        case FormGroupFieldType.ROW: {
          const { items, props, as } = item;
          formBuilder.addRow(
            groupId,
            fieldBuilder => {
              items.forEach(rowItem => {
                if (isFormField(rowItem)) {
                  addField(fieldBuilder, rowItem);
                } else {
                  buildForm(rowItem, fieldBuilder);
                }
              });
            },
            props,
            className,
            as ?? fieldSpecAs,
            viewVariant
          );

          break;
        }
        case FormGroupFieldType.FORM_ROW: {
          const { items, as } = item;
          formBuilder.addFormRow(
            groupId,
            fieldBuilder => {
              items.forEach(rowItem => {
                if (isFormField(rowItem)) {
                  addField(fieldBuilder, rowItem);
                } else {
                  buildForm(rowItem, fieldBuilder);
                }
              });
            },
            className,
            as,
            viewVariant
          );

          break;
        }
        case FormGroupFieldType.FIELDSET: {
          const {
            items,
            props,
            legend: layoutLegend,
            description: layoutDescription,
            className: layoutFieldsetClassName,
            toggleState: layoutToggleState,
            toggleButtonProps: layoutToggleButton,
            toggleCollapseProps: layoutToggleCollapse
          } = item;

          const fieldGroupDefinition = fieldGroupIdToFieldGroupMap[
            groupId
          ] as FormFieldsetType<FormDataT>;
          const fieldsetClassName = fieldGroupDefinition?.props?.className;
          const legendClassName = fieldGroupDefinition?.legend?.className;
          const legendLabel = fieldGroupDefinition?.legend?.label;
          const legendAs = fieldGroupDefinition?.legend?.as;
          const legendRole = fieldGroupDefinition?.legend?.role;
          const legendAriaLevel = fieldGroupDefinition?.legend?.ariaLevel;
          const descriptionClassName = fieldGroupDefinition?.description?.className;
          const descriptionLabel = fieldGroupDefinition?.description?.label;
          const toggleButtonClassName = fieldGroupDefinition?.toggleButtonProps?.className;
          const toggleCollapseClassName = fieldGroupDefinition?.toggleCollapseProps?.className;
          const ref = fieldGroupDefinition?.ref;
          const useIDAsHtmlID = fieldGroupDefinition?.useIDAsHtmlID;
          const toggleState = fieldGroupDefinition?.toggleState;

          const layoutLegendClassName = layoutLegend?.className;
          const adjustedLegend: Legend = {
            ...layoutLegend,
            label: legendLabel,
            className: mergeClassNames(
              { layoutClassName: layoutLegendClassName, cx: layoutCX },
              legendClassName
            ),
            as: legendAs,
            role: legendRole,
            ariaLevel: legendAriaLevel
          };

          const layoutDescriptionClassName = layoutDescription?.className;
          const adjustedDescription = {
            ...layoutDescription,
            label: descriptionLabel,
            className: mergeClassNames(
              { layoutClassName: layoutDescriptionClassName, cx: layoutCX },
              descriptionClassName
            )
          };
          const adjustedFieldsetClassName = mergeClassNames(
            { layoutClassName: layoutFieldsetClassName, cx: layoutCX },
            fieldsetClassName
          );

          const layoutToggleButtonClassName = layoutToggleButton?.className;
          const adjustedToggleButtonProps = {
            ...layoutToggleButton,
            className: mergeClassNames(
              { layoutClassName: layoutToggleButtonClassName, cx: layoutCX },
              toggleButtonClassName
            )
          };

          const layoutToggleCollapseClassName = layoutToggleCollapse?.className;
          const adjustedToggleCollapseProps = {
            ...layoutToggleCollapse,
            className: mergeClassNames(
              { layoutClassName: layoutToggleCollapseClassName, cx: layoutCX },
              toggleCollapseClassName
            )
          };

          formBuilder.addFieldGroup(
            groupId,
            fieldBuilder => {
              items.forEach(rowItem => {
                if (isFormField(rowItem)) {
                  addField(fieldBuilder, rowItem);
                } else {
                  buildForm(rowItem, fieldBuilder);
                }
              });
            },
            props,
            {
              toggleState: layoutToggleState || toggleState,
              legend: adjustedLegend,
              description: adjustedDescription,
              toggleButtonProps: adjustedToggleButtonProps,
              toggleCollapseProps: adjustedToggleCollapseProps,
              className: adjustedFieldsetClassName
            },
            viewVariant,
            useIDAsHtmlID,
            ref
          );

          break;
        }
        case FormGroupFieldType.COL: {
          const { items, props, as } = item;
          formBuilder.addColumn(
            groupId,
            fieldBuilder => {
              items.forEach(rowItem => {
                if (isFormField(rowItem)) {
                  addField(fieldBuilder, rowItem);
                } else {
                  buildForm(rowItem, fieldBuilder);
                }
              });
            },
            props ?? (fieldSpecProps as ColProps),
            as,
            className
          );

          break;
        }
      }
    }
  }

  const {
    className,
    rows,
    actionButtons,
    formGroupFieldSeparator: layoutFormGroupFieldSeparator
  } = layout;
  const formBuilder: FormBuilder<FormDataT> = new FormBuilder<FormDataT>(
    id,
    i18n,
    formSchema,
    {
      formClassName:
        className && formClassName
          ? `${layoutCX(className)} ${formClassName}`
          : className
          ? layoutCX(className)
          : formClassName,
      fieldsWrapperClassName,
      formTitleClassName,
      formGroupFieldSeparator: layoutFormGroupFieldSeparator || formSpecFormGroupFieldSeparator,
      fieldWatchers,
      useReCaptcha,
      reCaptchaClassName
    },
    customProps
  );

  rows.forEach(row => buildForm(row, formBuilder));

  const {
    formActionsSpec: {
      formActions,
      actionButtonBarPosition,
      className: formActionsClassName,
      actionButtonsPosition,
      outerContainerClassName,
      innerContainerClassName
    }
  } = formSpec;

  formBuilder.addFormActions(formActions);

  const {
    actionButtonsPosition: layoutActionButtonsPosition,
    actionButtonBarPosition: layoutActionButtonBarPosition,
    className: layoutFormActionsClassName,
    outerContainerClassName: layoutOuterContainerClassName,
    innerContainerClassName: layoutInnerContainerClassName
  } = actionButtons || {};

  formBuilder.setActionButtonsPosition(layoutActionButtonsPosition || actionButtonsPosition);
  formBuilder.setActionButtonBarPosition(layoutActionButtonBarPosition || actionButtonBarPosition);

  const mergedFormActionsClassName = mergeClassNames(
    { layoutClassName: layoutFormActionsClassName, cx: layoutCX },
    formActionsClassName
  );
  if (mergedFormActionsClassName) {
    formBuilder.setActionsClassName(mergedFormActionsClassName);
  }

  const mergedOuterContainerClassName = mergeClassNames(
    { layoutClassName: layoutOuterContainerClassName, cx: layoutCX },
    outerContainerClassName
  );

  if (mergedOuterContainerClassName) {
    formBuilder.setActionsOuterContainerClassName(mergedOuterContainerClassName);
  }

  const mergedInnerContainerClassName = mergeClassNames(
    { layoutClassName: layoutInnerContainerClassName, cx: layoutCX },
    innerContainerClassName
  );
  if (mergedInnerContainerClassName) {
    formBuilder.setActionsInnerContainerClassName(mergedInnerContainerClassName);
  }

  return formBuilder.build();
}

export default FormBuilder;
